.message-list {
    position: fixed;
    left: 10px;
    bottom: 10px;
}
.message-item {
    padding: 20px 30px;
    background: #333;
    margin-top: 10px;
    border-radius: 6px;
    color: #fff;
}