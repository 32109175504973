.informator {
    background: #fff;
    padding: 16px 24px;
    border-radius: 6px;
    border: 1px solid #dedede;
    margin-bottom: 20px;
}
.informator.info {
    background: #8eeeae;
    border: 1px solid #61e7a4;
}