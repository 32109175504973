.shop-view-block {
    display: flex;
    justify-content: center;
    width: 100%;
}
.shop-view {
    border-radius: 6px;
    padding: 24px 24px 28px 24px;
    margin: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
}
.shop-view:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.logo-view {
    text-align: center;
}
.logo-view.image {
    width: 180px;
    margin-bottom: 24px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo-view.image img {
    max-height: 100px;
}

.sales-count-row {
    border-bottom: 1px solid #dedede;
    padding-bottom: 12px;
}
.widget-block .sales-count-row:last-child,
.shop-view .sales-count-row:last-child {
    border-bottom: none;
    padding-bottom: 0;
}
.sales-count-row h5 {
    margin-bottom: 3px;
}
.sales-count-block {
    display: flex;
}
.sales-count span {
    color: #555;
    font-size: 14px;
}
.sales-count div {
    font-size: 24px;
    font-family: "ProximaSemibold";
}

.week-widget {
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
    padding: 20px 24px;
    transition: all 0.2s;
    margin-top: 24px;
    background: #fff;
    border: #e2e2e2 1px solid;
    border-radius: 6px;
}
.min-color-border {
    border-color: #DE2E62;
}
.mid-color-border {
    border-color: #ffc273;
}
.max-color-border {
    border-color: #14A889;
}
.week-widget h4 {
    margin: 0 0 18px 0;
    color: #333;
}
.week-widget-graph {
    width: 95%;
    margin: 0 auto;
    height: 4px;
    background-color: #dedede;
    border-radius: 6px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
}
.week-widget-numbers {
    position: relative;
    width: 95%;
    margin: 0 auto;
    height: 30px;
}
.week-widget-number {
    padding: 6px 10px;
    background: #555;
    border-radius: 4px;
    font-size: 12px;
    display: inline-block;
    color: #fff;
    position: absolute;
    transform: translateX(-50%);
    bottom: 8px;
    transition: all 0.4s;
    text-wrap: nowrap;
}
.week-widget-number::after {
    content: ''; 
    position: absolute;
    left: 50%;
    margin-left: -5px;
    bottom: -10px; 
    border: 5px solid transparent; 
    border-top: 5px solid #dedede;
}

.week-widget-number.min-color::after {
    border-top: 5px solid #DE2E62;
}

.week-widget-number.mid-color::after {
    border-top: 5px solid #ffc273;
}

.week-widget-number.max-color::after {
    border-top: 5px solid #14A889;
}

.week-widget-now {
    padding: 6px 10px;
    background: #dedede;
    border-radius: 4px;
    font-size: 12px;
    display: inline-block;
    color: #333;
    position: absolute;
    transform: translateX(-50%);
    top: 18px;
    transition: all 0.4s;
}
.week-widget-graph span {
    display: inline-block;
    width: 1px;
    height: 12px;
}
.min-color {
    background-color: #DE2E62;
    color: #fff;
}
.mid-color {
    background-color: #ffc273;
    color: #333;
}
.max-color {
    background-color: #14A889;
    color: #fff;
}