.sidebar {
    position: relative;
    position: relative;
    transition: all 0.5s;
}
.sidebar-fix {
    position: sticky;
    background: #fff;
    top: 0;
    padding: 10px;
}
.sidebar-inner {
    background: #DE2E62;
    width: 100%;
    border-radius: 6px;
}
.sidebar.true {
    flex: 0 0 200px
}
.sidebar.false {
    flex: 0 0 60px
}
.sidebar-toggle {
    width: 24px;
}
.sidebar-menu {
    padding-top: 16px;
}
.sidebar-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: "ProximaSemibold";
    font-size: 16px;
}
.sidebar-menu ul li {
    padding: 6px 6px 0 6px;
    cursor: pointer;
    color: #fff;
}
.sidebar-menu ul li span {
    margin-left: 10px;
    line-height: normal;
}
.sidebar-menu ul li:last-child {
    padding-bottom: 6px;
}
.menu-item-inner {
    display: flex;
    align-items: center;
    transition: all 0.2s;
    padding: 10px;
    border-radius: 6px;
}
.menu-item-inner:hover {
    background: #bb214f;
}
.icon-menu {
    margin: 0 16px 4px 16px;
    width: 24px;
    cursor: pointer;
}
.icon-menu-top, .icon-menu-middle-1, .icon-menu-middle-2, .icon-menu-bottom {
    transition: all 0.2s;
    transform-origin: center;
}
.icon-menu-top.true {
    opacity: 0;
}
.icon-menu-middle-1.true {
    transition: all 0.2s;
    transform-origin: center;
    transform: rotate(-45deg);
}
.icon-menu-middle-2.true {
    transition: all 0.2s;
    transform-origin: center;
    transform: rotate(45deg);
}
.icon-menu-bottom.true {
    opacity: 0;
}
.menu-item-letter {
    font-family: "ProximaRegular";
    font-size: 22px;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 1;
}