.header {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-logo img {
    cursor: pointer;
    height: 60px;
}
.header-right {
    display: flex;
}
.user-block-menu {
    position: relative;
}
.user-block {
    cursor: pointer;
    display: flex;
    align-items: center;
}
.user-icon {
    display: inline-block;
    background: #DC2058;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #fff;
    font-family: "ProximaSemibold";
    margin-right: 6px;
}
.user-icon-letter {
    vertical-align: -webkit-baseline-middle;
}
.user-menu {
    position: absolute;
    right: 0;
    top: 35px;
    width: 100px;
    border-radius: 6px 0 6px 6px;
}
.user-menu::before {
    content: "";
    display: block;
    width: 10px;
    border: 10px solid transparent;
    border-bottom: 10px solid #ececec;
    position: absolute;
    right: 0;
    top: -20px;
}
.user-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.user-menu ul li {
    cursor: pointer;
    padding: 10px 18px;
    background: #ececec;
    border-bottom: 1px solid #dedede;
}
.user-menu ul li:hover {
    background-color: #dedede;
}
.user-menu ul li:first-child {
    border-radius: 6px 0 0 0;
}
.user-menu ul li:last-child {
    border: none;
    border-radius: 0 0 6px 6px;
}