.main-shop-page {
    display: flex;
}
.main-shop-page-left {
    margin-right: 24px;
}
.main-shop-page-right {
    flex-grow: 1;
}

.widget-block {
    border-radius: 6px;
    padding: 24px 24px 28px 24px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    margin-bottom: 24px;
}
.widget-block:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.widget-block.linked {
    cursor: pointer;
}
.widget-block h4 {
    margin-top: 0;
    margin-bottom: 40px;
}
.widget-counter {
    font-family: "ProximaSemibold";
    font-size: 72px;
    line-height: 0;
    margin-bottom: 24px;
}

.ws-sidebar {
    flex: 0 0 250px;
    margin-right: 24px;
}
.ws-main {
    flex-grow: 1;
}

.list-view {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: -10px;
    padding-bottom: 24px;
}
.view-small {
    border-radius: 6px;
    width: 300px;
    display: flex;
    margin: 10px;
    padding: 20px 24px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    cursor: pointer;
}
.view-small:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.view-small-image {
    width: 100px;
    margin-right: 20px;
}
.view-small-title {
    text-overflow: ellipsis;
    width: 200px;
    white-space: nowrap;
    overflow:hidden;
}
.view-small-article {
    margin-bottom: 16px;
}

.view-small-purchase {
    margin: 10px 0;
}

.view-small-shops {
    display: flex;
}
.view-small-shops div {
    margin-right: 6px;
}

.simple-block {
    border: 1px solid #dedede;
    padding: 0 20px 20px;
    border-radius: 6px;
    flex-grow: 1;
}

.view-big {
    border-radius: 6px;
    width: 300px;
    margin: 10px;
    padding: 20px 24px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.view-big:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.view-big-image {
    position: relative;
}
.view-big-article {
    position: absolute;
    left: 10px;
    bottom: 10px;
    background: #2079dc;
    color: #fff;
    padding: 6px 12px;
    border-radius: 4px;
}
.view-big-purchase {
    position: absolute;
    left: 10px;
    bottom: 10px;
    background: #fff;
    color: #333;
    padding: 6px 12px;
    border-radius: 4px;
}
.view-big-shops {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background: #fff;
    color: #333;
    padding: 6px 12px;
    border-radius: 4px;
}
.view-big-title {
    margin-bottom: 10px;
}
.chip-article {
    display: inline-block;
    background: #2079dc;
    color: #fff;
    padding: 6px 12px;
    border-radius: 4px;
}

.view-chip-shops {
    background-color: #2079dc;
    color: #fff;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 6px;
    margin: 4px 0;
    text-transform: uppercase;
}

.settings-block {
    border-radius: 6px;
    padding: 24px 24px 28px 24px;
    width: 300px;
    height: 200px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    margin-bottom: 24px;
    margin: 10px;
}
.settings-block:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.settings-block.linked {
    cursor: pointer;
}
.settings-block div {
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
}
.settings-block h3 {
    margin: 0;
    font-family: "ProximaSemibold";
}