.pricer {
    margin: 20px -10px;
    display: flex;
    flex-wrap: wrap;
}
.pricer-block {
    flex: 0 0 800px;
    border-radius: 6px;
    margin: 10px;
    padding: 20px 24px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    display: flex;
}
.pricer-block.active {}
.pricer-block:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.pricer-block-img {
    flex: 0 0 100px;
    position: relative;
}
.pricer-form {
    flex: 0 0 250px;
    margin-right: 24px;
}
.pricer-log {
    flex-grow: 1;
    padding-left: 24px;
    border-left: 1px solid #dedede;
}
.pricer-indicator {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #ccc;
}
.pricer-indicator.active {
    background-color: #14A889;
}
.pricer-block-img img {
    max-height: 120px;
}
.pricer-block-content {
    flex-grow: 1;
}
.form-row-flex {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-bottom: 8px;
    position: relative;
}
.form-row-flex-end {
    padding-bottom: 10px;
    margin-left: 16px;
    color: #777;
    font-size: 16px;
}

.service-datas {
    display: flex;
}
.service-data {
    flex: 0 0 250px;
    padding: 0 24px 20px 24px;
    background: #14A889;
    color: #fff;
    margin-right: 24px;
    border-radius: 10px;
}