@font-face {
    font-family: "ProximaRegular";
    src: local("ProximaSoft-Regular"),
     url("../src/assets/fonts/ProximaNova-Regular.ttf") format("truetype");
    font-weight: normal;
    }

@font-face {
    font-family: "ProximaSemibold";
    src: local("ProximaSoft-Semibold"),
        url("../src/assets/fonts/ProximaNova-Semibold.ttf") format("truetype");
    font-weight: normal;
    }
body {
    font-family: "ProximaRegular";
}
.noscroll {
    position: fixed;
    top: var(--st, 0);
    inline-size: 100%;
    overflow-y:hidden;
}
.icon {
    width: 24px;
    height: 24px;
}
.icon.active {
    cursor: pointer;
}
.flex {
    display: flex;
}
.link {
    color: #2079dc;
    border-bottom: 1px solid #badaff;
    cursor: pointer;
}
.link:hover {
    color: #0e6fdd;
    border-bottom: 1px solid #daebff;
}
.page-center-align-v {
    display: flex;
    width: 1;
    height: 100vh;
    align-items: center;
    justify-content: center;
}
.page-center-align {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.button {
    background: #2079dc;
    border: none;
    border-radius: 10px;
    padding: 16px 20px;
    color: #fff;
    font-family: "ProximaSemibold";
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 1px;
    transition: all 0.2s;
}
.button:hover:enabled {
    background: #1368c9;
    border-radius: 30px;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(5, 22, 112, 0.2);
}
.button.small {
    padding: 8px 12px;
}
.button.cancel {
    background-color: #ccc;
    color: #333;
}
.button.cancel:hover {
    background-color: #bbbbbb;
    color: #333;
}
.button-delete {
    background-color: #DE2E62;
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center center;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    color: #fff;
    font-family: "ProximaSemibold";
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 1px;
    transition: all 0.2s;
}
.button-delete:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(5, 22, 112, 0.2);
}

.form-label {
    font-size: 16px;
    margin-bottom: 6px;
}
.text-field {
    position: relative;
    display: block;
    width: 100%;
    /* height: calc(2.25rem + 2px); */
    padding: 10px 18px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #bdbdbd;
    border-radius: 10px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.text-field.error {
    border-color: #a01a42;
}
.select-field {
    position: relative;
    display: block;
    /* height: calc(2.25rem + 2px); */
    padding: 10px 30px 10px 18px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #bdbdbd;
    border-radius: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%333%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'); 
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%; 
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.select-field::-ms-expand { display: none; } 
.date-field {
    position: relative;
    padding: 10px 18px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #bdbdbd;
    border-radius: 10px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.from-to {
    margin-bottom: 24px;
}
.from-to input {
    margin-right: 12px;
}
.form-row {
    margin-bottom: 8px;
    position: relative;
}
.hide-show-password {
    position: absolute;
    right: 10px;
    top: 36px;
}
.mb-24 {
    margin-bottom: 24px;
}
.mb-12 {
    margin-bottom: 12px;
}
.mr-10 {
    margin-right: 10px;
}
.mr-20 {
    margin-right: 20px;
}

.page-title h1 {
    margin-top: 10px;
}

.button-group {
    display: flex;
    margin-bottom: 16px;
}
.button-group-item {
    background: #f1f1f1;
    font-size: 12px;
    border: none;
    padding: 12px 16px;
    color: #555;
    font-family: "ProximaSemibold";
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 1px;
    transition: all 0.2s;
    border-right: 1px solid #fff;;
}
.button-group-item.true {
    background: #ddd;
}
.button-group .button-group-item:first-child {
    border-radius: 6px 0 0 6px;
}
.button-group .button-group-item:last-child {
    border-radius: 0 6px 6px 0;
    border-left: none;
}
button:disabled,
button[disabled]{
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}
button:disabled:hover,
button[disabled]:hover{
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}
.table {
    width: 100%;
    border-spacing: inherit;
}
.table thead tr th {
    text-align: left;
    border-bottom: 1px solid #dedede;
    padding: 6px 0;
}
.table tbody tr {
    background: #fff;
    cursor: pointer;
}
.table tbody tr:hover td {
    background: #dedede;
}
.table tbody tr td {
    border-bottom: 1px solid #dedede;
    padding: 10px 6px;
}
.table tbody tr:last-child td {
    border-bottom: 3px solid #777;
}
.dot {
    width: 12px;
    height: 12px;
    background: #ccc;
    border-radius: 50%;
    position: relative;
}
.dot::after {
    content: "ASD";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    top: 6px;
    left: 6px;
    padding: 4px 8px;
    color: #fff;
    font-size: 12px;
    border-radius: 0 4px 4px 4px;
    display: none;
}
.dot.final {
    background-color: #14A889;
}
.dot.dot.final::after {
    content: "Итог";
}
.dot.dot.final:hover::after, .dot.dot.pre:hover::after  {
    display: block;
}
.dot.pre {
    background-color: #FFE073;
}
.dot.dot.pre::after {
    content: "Предварительно";
}
.level {
    background: #ccc;
    padding: 4px 8px;
    border-radius: 6px;
    font-family: "ProximaSemibold";
}
.level.critical {
    background-color: #a01a42;
    color: #fff;
}
.level.low {
    background-color: #DE2E62;
    color: #fff;
}
.level.normal {
    background-color: #FFE073;
    color: #333;
}
.level.good {
    background-color: #14A889;
    color: #fff;
}

.table-params {
    width: 100%;
    border-spacing: inherit;
}
.table-params thead tr th {
    text-align: left;
    border-bottom: 1px solid #dedede;
    padding: 6px 0;
}
.table-params tbody tr {
    background: #fff;
}
.table-params tbody tr:hover td {
    background: #f1f1f1;
}
.table-params tbody tr td {
    border-bottom: 1px solid #dedede;
    padding: 10px 6px;
}
.brand-block {
    border-radius: 6px;
    padding: 24px 24px 28px 24px;
    width: 300px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    margin-bottom: 24px;
    margin: 10px;
}
.brand-block:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.brand-block.linked {
    cursor: pointer;
}
.brand-block img {
    height: 40px;
}
.brand-block h3 {
    margin: 0;
    font-family: "ProximaSemibold";
}
.search-desc {
    width: 250px;
}

.wrap-root {
    display: flex;
    position: relative;
    min-height: 100vh;
}

.wrap-root-main {
    flex: auto;
    padding-right: 20px;
    padding-left: 20px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.wrap-root-side {}

@media (max-width: 1600px) {
    .wrap-root-side {}
    .wrap-root-main {}
    .wrap-root {
        flex-direction: column;
    }
}

@media (min-width: 1600px) {
    .wrap-root-side {
        flex: 0 0 350px;
    }
    .wrap-root {
        flex-direction: row;
        padding-right: 0px;
    }
}

@media (min-width: 2000px) {
    .wrap-root-side {
        flex: 0 0 500px;
    }
}

.product-title {
    display: flex;
    margin-bottom: 24px;
}
.product-title-image {
    flex: 0 0 300px;
    margin-right: 24px;
}
/* The switch - the box around the slider */
.switcher {
    display: flex;
    align-items: center;
}
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    margin-right: 6px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 20px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0;
    border-bottom: 1px solid #dedede;
  }

  .connector {
    position: absolute;
    left: 0;
    bottom: -12px;
    width: 100%;
    min-height: 55px;
    padding: 18px 24px;
    background-color: #fff;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  .view-small-brand {
    background: #dedede;
    width: fit-content;
    padding: 3px 6px;
    font-size: 12px;
    border-radius: 4px;
  }

  .view-big-brand {
    background: #fff;
    width: fit-content;
    padding: 3px 6px;
    font-size: 12px;
    border-radius: 4px;
    position: absolute;
    left: 10px;
    bottom: 50px;
  }
  .shop-count-chips {
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
  }
  .shop-count-chips div {
    min-width: 50px;
    flex: 0 0 50px;
  }
  .shop-count-chips div span {
    background-color: #0e6fdd;
    color: #fff;
    width: 20px;
    padding: 3px;
    display: inline-block;
    text-align: center;
    margin: 0 0 0 4px;
    border-radius: 10px;
  }

  .services-block {
    display: flex;
    margin: -10px;
    flex-wrap: wrap;
  }
  .pricer-product-block {
    margin: 10px;
    min-width: 300px;
  }

  .service {
    margin: 10px;
    flex: 0 0 350px;
  }