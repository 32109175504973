.pagination {}
.pagination ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.pagination ul li {
    padding: 6px 14px;
    border: 1px solid #dedede;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
}
.pagination ul li:hover {
    background: #dedede;
}
.pagination ul li.active {
    background: #2079dc;
    border: 1px solid #2079dc;
    color: #fff;
    cursor: default;
}
.sorting-data {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;
}